export const hostMapping = {
  // Client-side hosts (with https://)
  "https://bahagia.day": "https://official.bahagia.day",
  "https://www.bahagia.day": "https://official.bahagia.day",
  "https://app.bahagia.day": "https://official.bahagia.day",
  // "http://127.0.0.1:3000": "http://127.0.0.1:3000",

  // Server-side hosts (without https://)
  "bahagia.day": "official.bahagia.day",
  "www.bahagia.day": "official.bahagia.day",
  "app.bahagia.day": "official.bahagia.day",
  // "127.0.0.1:3000": "127.0.0.1:3000",
};

export const getHostMapping = (host: string): string => {
  return hostMapping[host] || host;
};