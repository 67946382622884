/* eslint-disable */
import { Button, Card, Center, Flex, Modal, Text } from "@mantine/core";
import { DatePicker } from "@mantine/dates";
import React, { useState } from "react";

function BookingDateSectionContent({
  sectionData,
  data,
  brand,
}: {
  sectionData: any;
  data?: any;
  brand?: any;
}) {
  const [selectedDate, setSelectedDate] = useState<Date | null>(null);
  const [showModal, setShowModal] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [modalContent, setModalContent] = useState<string>("");
  const [dateToBook, setDateToBook] = useState<Date | null>(null);
  // console.log(brand)

  // Convert sectionData content to a Set of booked dates for quick lookup
  const bookedDates = new Set(
    sectionData?.content?.map(
      (item: any) => new Date(item.date).toISOString().split("T")[0]
    ) || []
  );

  // Handle date selection
  const handleDateChange = (date: Date | null) => {
    if (date) {
      const formattedDate = date.toISOString().split("T")[0]; // Format date as YYYY-MM-DD
      setSelectedDate(date);
      if (bookedDates.has(formattedDate)) {
        setModalContent("Maaf Kak, tanggal ini sudah di-booking");
        setShowModal(true);
      } else {
        // Show confirmation modal
        setDateToBook(date);
        setShowConfirmModal(true);
      }
    }
  };

  const handleConfirmBooking = () => {
    if (dateToBook) {
      const formattedDate = dateToBook?.toLocaleDateString("id-ID", {
        day: "numeric",
        month: "long",
        year: "numeric",
      });
      window.open(
        // `https://wa.me/${brand.whatsAppNumber}?text=Informasi%20tanggal%20booking%20%3A%20${formattedDate}`,
        `https://api.whatsapp.com/send?phone=${brand.whatsAppNumber}&text=Halo%20%2A${brand.name}%2A%2C%20%0D%0AMau%20booking%20dong%20buat%20tanggal%20${formattedDate}`,
        "_blank"
      );
      setShowConfirmModal(false);
    }
  };

  return (
    <Card sx={{backgroundColor: "#F2F2F2", margin: "16px"}} radius={"lg"} withBorder>
      <div className="relative flex shrink-0 grow-0 flex-col items-start justify-center gap-1 self-stretch">
        <p className="text-md w-full text-center shrink-0 grow-0 self-stretch whitespace-normal text-left font-bold text-[#38465b] py-0 my-0">
          {sectionData.title}
        </p>
        <p className="w-full shrink-0 text-center grow-0 self-stretch whitespace-normal text-left text-sm text-[#7c7d8a] py-0 my-0">
          {sectionData.description}
        </p>
      </div>

      <Center mt={16} pb={16}>
        <Card radius={"lg"} withBorder>
          <DatePicker
            value={selectedDate}
            onChange={(date) => handleDateChange(date)}
            renderDay={(date) => {
              const formattedDate = date.toISOString().split("T")[0];
              const isBooked = bookedDates.has(formattedDate);
              const isSelected =
                selectedDate?.toISOString().split("T")[0] === formattedDate;

              return (
                <div
                  style={{
                    backgroundColor: isSelected
                      ? "#ffd0e2" // Pink color for selected dates
                      : isBooked
                      ? "#ffd0e2" // Color for booked dates
                      : "transparent",
                    borderRadius: "10%",
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    // color: isBooked || isSelected ? "#000" : "#a3a3a3",
                    // opacity: !isBooked && !isSelected ? 0.5 : 1, // Reduce opacity for booked dates that are not selected
                  }}
                >
                  {date.getDate()}
                </div>
              );
            }}
            minDate={new Date()}
            allowDeselect
          />
        </Card>
      </Center>

      {/* Modal for unavailable dates */}
      <Modal
        opened={showModal}
        onClose={() => setShowModal(false)}
        title="Informasi Booking"
        centered
      >
        <Text>{modalContent}</Text>
        <Button onClick={() => setShowModal(false)} mt="md">
          Tutup
        </Button>
      </Modal>

      {/* Confirmation modal before booking */}
      <Modal
        opened={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        title={
          <Center>
            <Text>Konfirmasi Booking</Text>
          </Center>
        }
        centered
        withCloseButton
      >
        <Text align="center">
          Kamu akan mem-booking tanggal: <br />
          <Text component="span" weight={500}>
            {dateToBook?.toLocaleDateString("id-ID", {
              day: "numeric",
              month: "long",
              year: "numeric",
            })}
          </Text>
        </Text>

        <Flex gap={10} w={"100%"}>
          {/* <Button
            fullWidth
            radius={"md"}
            variant="outline"
            onClick={() => setShowConfirmModal(false)}
            mt="md"
            sx={{
              backgroundColor: "white!important",
              border: "1px solid #38465B",
              color: "#38465B",
            }}
          >
            Batal
          </Button> */}
          <Button
            variant="filled"
            onClick={handleConfirmBooking}
            radius={"md"}
            // color="green"
            sx={{ backgroundColor: "#EFEFEF!important", color: "#38465B" }}
            mt="md"
            fullWidth
          >
            Konfirmasi
          </Button>
        </Flex>
      </Modal>
    </Card>
  );
}

export default BookingDateSectionContent;
